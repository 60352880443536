







import Sidebar from "@/components/Sidebar.vue";
import {
  computed,
  defineComponent,
  ref,
  provide,
  reactive,
  watch,
} from "@vue/composition-api";
import Tapbar from "@/components/Tapbar.vue";
import CreateWizard from "@/components/CreateWizard.vue";
import ParentView from "@/components/ParentView.vue";
import IStep from "@/interfaces/IStep";
import { User } from "@/graphql/types";
import useAssignRoleOnBehalfOfMutation from "./Composables/useAssignRoleOnBehalfOfMutation";
import useVuelidate from "@vuelidate/core";
import ITabContent from "@/interfaces/ITabContent";
import AssignRoleEmail from "./Components/AssignRoleEmail.vue";
import AssignedUserRoles from "./Components/AssignedUserRoles.vue";
import UserRoles from "./Components/UserRoles.vue";
import { UserKey, IsUserEditKey } from "./symbols";
import useProfile from "@/composables/useProfile";
import { EditableKey } from "@/symbols";
import useNextStepButton from "@/composables/buttons/useNextStepButton";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useGobackButton from "@/composables/buttons/useGobackButton";

export default defineComponent({
  components: { Sidebar, Tapbar, CreateWizard, ParentView },
  setup(_, { root }) {
    const { isCentralAdmin, isLocalAdmin, isAppAdmin } = useProfile();
    const editable = computed(
      () => isCentralAdmin.value || isLocalAdmin.value || isAppAdmin.value
    );

    const v$ = useVuelidate();
    const currentTab = ref(0);

    const user = reactive<User>({
      id: "",
      email: "",
      organization: {},
      roleAssignments: [],
    });

    const hasChanges = ref(false);

    provide(UserKey, user);
    provide(IsUserEditKey, false);
    provide(EditableKey, editable);

    const assignMutation = useAssignRoleOnBehalfOfMutation(user);

    assignMutation.onDone(() => {
      currentTab.value++;
    });

    watch(
      () => [user.email],
      () => {
        if (hasChanges.value == false) {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );

    return {
      currentTab,
      tabs: computed<ITabContent[]>(() => [
        {
          title: root.$tc("users.fields.email"),
          component: AssignRoleEmail,
          primaryButton: {
            ...useNextStepButton(async () => {
              if (await v$.value.$validate()) currentTab.value++;
            }),
            disabled: !hasChanges.value || v$.value.$errors.length > 0,
          },
          secondaryButton: useCancelButton(() => root.$router.back()),
        },
        {
          title: root.$tc("roles.title"),
          component: UserRoles,
          primaryButton: {
            title: root.$tc("common.steps.done"),
            variant: "success",
            onClick: async () => {
              if (await v$.value.$validate()) currentTab.value++;
            },
            disabled: v$.value.$invalid || v$.value.$errors.length > 0,
          },
          secondaryButton: useGobackButton(() => currentTab.value--),
        },
        {
          title: root.$tc("common.done"),
          component: AssignedUserRoles,
        },
      ]),
      steps: computed<IStep[]>(() => [
        {
          title: root.$tc("users.fields.email"),
          icon: "envelope",
        },
        {
          title: root.$tc("roles.title"),
          icon: "share-alt",
        },
        {
          title: root.$tc("common.steps.done"),
          icon: "check",
        },
      ]),
    };
  },
});
