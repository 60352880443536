

















import { computed, defineComponent } from "@vue/composition-api";
import { DateTime } from "luxon";
import DatePicker from "./DatePicker.vue";

export default defineComponent({
  components: { DatePicker },
  props: {
    expirationDate: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    return {
      timelimited: computed<boolean>({
        get(): boolean {
          return props.expirationDate != null;
        },
        set(newValue: boolean): void {
          // We want all dates to be in UTC, and at the start of day
          if ((newValue && props.expirationDate == null) || !newValue)
            emit(
              "update:expirationDate",
              newValue
                ? DateTime.now()
                    .toUTC()
                    .plus({ days: 1 })
                    .startOf("day")
                    .toISO()
                : null
            );
        },
      }),
      expirationTime: computed<DateTime | undefined>({
        get(): DateTime | undefined {
          return props.expirationDate
            ? DateTime.fromISO(props.expirationDate, { zone: "utc" })
            : undefined;
        },
        set(newValue: DateTime | undefined): void {
          // When a date is set we want to convert it to UTC, and to be at beginning of day
          emit(
            "update:expirationDate",
            newValue
              ? newValue
                  .startOf("day")
                  .setZone("utc", { keepLocalTime: true })
                  .toISO()
              : null
          );
        },
      }),
    };
  },
});
