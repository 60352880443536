import useDeleteFromCache from "@/composables/useDeleteFromCache";
import {
  AssignUserRoleInput,
  useAssignRoleToUserMutation,
  useRemoveRoleFromUserMutation,
  UserRoleAssignment,
  useUpdateRoleAssignmentMutation,
} from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { computed } from "@vue/composition-api";
import { ParentQueryKey } from "../symbols";

export default function () {
  const addMutation = useAssignRoleToUserMutation({});
  const removeMutation = useRemoveRoleFromUserMutation({});
  const editMutation = useUpdateRoleAssignmentMutation({});

  const parentQuery = injectStrict(ParentQueryKey);

  function remove(input: UserRoleAssignment, userId: string) {
    return removeMutation.mutate(
      {
        input: {
          roleId: input.role?.id ?? "",
          userId: userId,
          onBehalfOfOrganizationId: input.organization?.id ?? "",
        },
      },
      {
        // We have to refetchqueries, as user could be OBO user and therfore disapear if no longer has any roles obo logged in users org
        refetchQueries: [parentQuery],
        update: (cache) => {
          // We delete roles cache, as role count has been changed.
          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  async function edit(
    roleId: string,
    userId: string,
    organizationId: string,
    expirationDate: any
  ) {
    return await editMutation.mutate({
      input: {
        roleId: roleId,
        userId: userId,
        onBehalfOfOrganizationId: organizationId,
        expirationTime: expirationDate,
      },
    });
  }

  function add(input: AssignUserRoleInput) {
    return addMutation.mutate(
      {
        input: {
          roleId: input.roleId,
          userId: input.userId,
          expirationTime: input.expirationTime,
          isOBOAssignment: input.isOBOAssignment,
        },
      },
      {
        update: (cache) => {
          // We delete roles cache, as role count has been changed.
          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  return {
    add,
    remove,
    edit,
    loading: computed(
      () =>
        addMutation.loading.value ||
        removeMutation.loading.value ||
        editMutation.loading.value
    ),
  };
}
