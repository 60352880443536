








































import InputGroup from "@/components/InputGroup.vue";
import Popover, { PopoverProps } from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import { Organization, Role, UserRoleAssignment } from "@/graphql/types";
import Select from "@/components/Select.vue";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import IButton from "@/interfaces/IButton";
import DatePicker from "@/components/DatePicker.vue";
import ExpirationTimeSelector from "@/components/ExpirationTimeSelector.vue";
import useCancelButton from "@/composables/buttons/useCancelButton";
import RoleSelector from "@/components/Selectors/RoleSelector.vue";
import useUserRoleAssignmentMutations from "../Composables/useUserRoleAssignmentMutations";

export default defineComponent({
  components: {
    Popover,
    Table,
    InputGroup,
    Select,
    DatePicker,
    ExpirationTimeSelector,
    RoleSelector,
  },
  emits: ["roleAssigned"],
  props: {
    ...PopoverProps,
    userId: {
      type: String,
      required: true,
    },
    immediateAssignment: {
      type: Boolean,
      required: true,
    },
    organization: {
      type: Object as PropType<Organization>,
      required: true,
    },
    userRoleAssignments: {
      type: Array as PropType<UserRoleAssignment[]>,
      default: () => [],
    },
  },
  setup(props, { emit, root }) {
    const show = ref(false);
    const isEdit = ref(false);
    const role = ref(undefined as Role | undefined);
    const expirationTime = ref(undefined as string | undefined);

    const originalUserAssignment = ref(null as UserRoleAssignment | null);

    function disabledItem(role: Role) {
      return props.userRoleAssignments.some(
        (assignment) =>
          assignment.role?.id == role.id &&
          assignment.organization?.id == props.organization.id
      );
    }

    const {
      add,
      edit,
      loading: mutationLoading,
    } = useUserRoleAssignmentMutations();

    // Functions
    async function assignRole() {
      if (role.value == undefined) return;
      if (props.immediateAssignment) {
        var result = await add({
          roleId: role.value.id,
          userId: props.userId,
          expirationTime: expirationTime.value,
        });
        if (result?.errors == null)
          emit("roleAssigned", {
            role: role.value,
            organization: props.organization,
            expirationDate: expirationTime.value,
          });
        show.value = false;
      } else {
        emit("roleAssigned", {
          role: role.value,
          organization: props.organization,
          expirationDate: expirationTime.value,
        });
        show.value = false;
      }
    }

    async function updateRole() {
      if (role.value == undefined) return;
      const result = await edit(
        role.value.id,
        props.userId,
        originalUserAssignment.value?.organization?.id,
        expirationTime.value
      );
      if (result?.errors == null) {
        emit("roleUpdated", {
          role: role.value,
          organization: props.organization,
          expirationDate: expirationTime.value,
        });
        show.value = false;
      }
    }

    function editAssignment(userRoleAssignment: UserRoleAssignment) {
      if (userRoleAssignment.role == null) return;
      originalUserAssignment.value = userRoleAssignment;
      role.value = userRoleAssignment.role;
      expirationTime.value = userRoleAssignment.expirationDate;
      isEdit.value = true;
      show.value = true;
    }

    watch(
      () => show.value,
      (newValue) => {
        if (!newValue) {
          isEdit.value = false;
          originalUserAssignment.value = null;
          role.value = undefined;
          expirationTime.value = undefined;
        }
      }
    );

    return {
      isEdit,
      show,
      disabledItem,
      editAssignment,
      role,
      expirationTime,
      primaryButton: computed<IButton>(() => {
        return {
          title: root.$tc(isEdit.value ? "common.save" : "common.assign"),
          variant: "success",
          onClick: () => (isEdit.value ? updateRole() : assignRole()),
          disabled:
            mutationLoading.value ||
            role.value == null ||
            (isEdit.value &&
              originalUserAssignment.value?.expirationDate ==
                expirationTime.value),
        };
      }),
      secondaryButton: useCancelButton(() => (show.value = false)),
    };
  },
});
