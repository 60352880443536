import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { useAssignRoleOnBehalfOfMutation, User } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { ParentQueryKey } from "../symbols";

export default function (user: User) {
  const mutation = useAssignRoleOnBehalfOfMutation({});
  const parentQuery = injectStrict(ParentQueryKey);

  function call() {
    mutation.mutate(
      {
        input: {
          email: user.email ?? "",
          roles:
            user.roleAssignments?.map((element) => {
              return {
                roleId: element?.role?.id,
                expirationTime: element?.expirationDate,
              };
            }) ?? [],
        },
      },
      {
        // We refresh query, as we do not know where new user would potentially be present in pagination
        refetchQueries: [parentQuery],
        update: (cache) => {
          // We delete roles cache, as role count has been changed.
          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
