var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('div',{staticClass:"h-100"},[_c('Table',{attrs:{"error":_vm.error,"fields":_vm.fields,"title":_vm.$tc('roles.title'),"options":_vm.options,"isLoading":_vm.loading,"items":_vm.roleAssignments ? _vm.roleAssignments : []},scopedSlots:_vm._u([{key:"cell(role.name)",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(value)+" "),(item.role.description)?_c('Tooltip',{staticClass:"ml-2"},[_vm._v(_vm._s(item.role.description))]):_vm._e()],1),(
          !_vm.isFullScreen && item.role.application && item.role.application.name
        )?_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(item.role.application.name))]):_vm._e()]}},{key:"cell(organization.name)",fn:function(ref){
        var value = ref.value;
        var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "),(item.organization && item.organization.vat)?_c('p',{staticClass:"text-muted mb-0"},[_vm._v(" "+_vm._s(item.organization.vat)+" ")]):_vm._e()]}},{key:"cell(lastUse)",fn:function(data){return [_c('div',[_vm._v(_vm._s(_vm._f("formatDate")(data.value)))]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.useFromNowDateFormatted(data.value)))])]}},{key:"cell(expirationDate)",fn:function(ref){
        var value = ref.value;
return [(value)?_c('Tooltip',{attrs:{"variant":_vm.useHasPassed(value) ? 'warning' : undefined,"size":"lg","icon":"clock"}},[_vm._v(_vm._s(_vm.$t("roles.assignment.timelimited_title"))+" "+_vm._s(_vm._f("formatDate")(value))+" - "+_vm._s(_vm.useFromNowDateFormatted(value)))]):_vm._e()]}},{key:"head(options)",fn:function(){return [_c('AssignRolePopupV2',{attrs:{"assigneeRoles":_vm.roleAssignments,"userId":_vm.user.id,"organization":_vm.homeOrg,"loadingRemoveRoleAssignment":_vm.loadingRemoveRoleAssignment,"isUserAssignable":_vm.isUserAssignable},on:{"roleAssigned":_vm.addedRoleAssignment,"roleRemove":_vm.removeRoleAssignment}})]},proxy:true}],null,false,154999940)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }