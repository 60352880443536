











































































import { defineComponent, ref, computed, PropType } from "@vue/composition-api";
import Popover from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import {
  Role,
  Organization,
  UserRoleAssignment,
  useGetSelectableRolesQuery,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import { TableField } from "@/types/TableField";
import useUserRoleAssignmentMutations from "../Composables/useUserRoleAssignmentMutations";

export default defineComponent({
  emits: ["roleAssigned", "roleRemove"],
  components: { Popover, Table },
  props: {
    assigneeRoles: {
      type: Array as PropType<UserRoleAssignment[]>,
      default: () => [],
    },
    organization: {
      type: Object as PropType<Organization>,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    loadingRemoveRoleAssignment: {
      type: Boolean,
      required: true,
    },
    isUserAssignable: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const show = ref(false);
    const { result, loading } = useGetSelectableRolesQuery({
      fetchPolicy: "cache-first",
    });

    const { add, loading: mutationLoading } = useUserRoleAssignmentMutations();

    function isAssigned(role: Role) {
      return props.assigneeRoles.some(
        (element) =>
          element.role?.id == role.id &&
          element.organization?.id == props.organization?.id
      );
    }

    // Functions
    async function addRoleAssignee(role: Role) {
      var result = await add({
        roleId: role.id,
        userId: props.userId,
      });
      if (result?.errors == null)
        emit("roleAssigned", {
          role: role,
          organization: props.organization,
        });
    }

    function removeRoleAssignee(role: Role) {
      emit("roleRemove", {
        role: role,
        organization: props.organization,
      });
    }

    return {
      show,
      loading,
      mutationLoading: computed(
        () => mutationLoading.value || props.loadingRemoveRoleAssignment
      ),
      roles: useResult(result, [], (data) => data.roles.nodes),
      fields: computed<TableField[]>(() => [
        { key: "attribute", filter: true, focus: true },
      ]),
      isAssigned,
      addRoleAssignee,
      removeRoleAssignee,
    };
  },
});
