


































































import Table from "@/components/Table.vue";
import AssignRolePopup from "@/views/Users/Components/AssignRolePopup.vue";
import AssignRolePopupV2 from "@/views/Users/Components/AssignRolePopupV2.vue";
import {
  computed,
  defineComponent,
  inject,
  ref,
  toRef,
  watch,
} from "@vue/composition-api";
import Tooltip from "@/components/Tooltip.vue";
import injectStrict from "@/utilities/injectstrict";
import { IsUserEditKey, UserKey } from "../symbols";
import {
  useGetUserRoleAssignmentsQuery,
  useGetUserRoleAssignmentsByEmailQuery,
  UserRoleAssignment,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import IMenu from "@/interfaces/IMenu";
import useNavigateToRole from "@/composables/navigation/useNavigateToRole";
import useNavigateToOrganization from "@/composables/navigation/useNavigateToOrganization";
import useNavigateToApplication from "@/composables/navigation/useNavigateToApplication";
import { TableField } from "@/types/TableField";
import useProfile from "@/composables/useProfile";
import { FullScreenKey } from "@/symbols";
import useVuelidate from "@vuelidate/core";
import useCanNavigate from "@/composables/useCanNavigate";
import useCanRemove from "@/composables/useCanRemove";
import useCanAssign from "@/composables/useCanAssign";
import {
  useFromNowDateFormatted,
  useHasPassed,
} from "@/composables/useDateFormat";
import useUserRoleAssignmentMutations from "../Composables/useUserRoleAssignmentMutations";
import useRemoveOption from "@/composables/options/useRemoveOption";
import useShowOption from "@/composables/options/useShowOption";

export default defineComponent({
  components: { Table, AssignRolePopup, Tooltip, AssignRolePopupV2 },
  setup(props, { root }) {
    // Refs
    const popup = ref<InstanceType<typeof AssignRolePopup>>();

    const { homeOrg } = useProfile();

    const user = injectStrict(UserKey);
    const roleAssignments = toRef(user, "roleAssignments");

    const isUserEdit = inject(IsUserEditKey, false);
    const isFullScreen = injectStrict(FullScreenKey);
    const isUserAssignable = computed(
      () =>
        isUserEdit === false || // Should enable this button in OBO scenario
        useCanAssign("UserRoleAssignment", user)
    );

    const requiredRoleAssignments = () => {
      if (roleAssignments?.value && roleAssignments?.value?.length >= 1)
        return true;
      return false;
    };

    const v$ = useVuelidate(
      { requiredRoleAssignments },
      {},
      { $autoDirty: true }
    );

    // Data
    const { result, loading, error, refetch } = isUserEdit
      ? useGetUserRoleAssignmentsQuery(
          () => ({
            id: user.id ?? "",
          }),
          () => ({
            enabled: user.id != null || user.id != "",
            fetchPolicy: "no-cache",
          })
        )
      : useGetUserRoleAssignmentsByEmailQuery(
          () => ({
            email: user.email ?? "",
          }),
          () => ({
            enabled: user.id != null || user.id != "",
            fetchPolicy: "no-cache",
          })
        );

    const { remove, loading: loadingRemoveRoleAssignment } =
      useUserRoleAssignmentMutations();
    watch(
      () => useResult(result).value,
      (newValue) => {
        if (newValue?.roleAssignments) {
          roleAssignments.value = newValue?.roleAssignments ?? [];
          roleAssignments.value.map((roleAssignment) => {
            // Assign context user to role assignment
            if (roleAssignment) {
              roleAssignment.user = user;
            }
            return roleAssignment;
          });
        }
      },
      { immediate: true }
    );

    function addedRoleAssignment(item: UserRoleAssignment) {
      item.user = user; // assign context user into role assignment => to make sure we can use useCanRemove and useCanAssign
      roleAssignments.value?.push(item);
    }

    function removedRoleAssignment(item: UserRoleAssignment) {
      roleAssignments.value = roleAssignments.value?.filter(
        (element) =>
          element?.role?.id != item.role?.id ||
          element?.organization?.id != item.organization?.id
      );
    }

    async function removeRoleAssignment(item: UserRoleAssignment | undefined) {
      if (item == undefined) return;
      var result = await remove(item, user.id);
      if (!result?.errors) removedRoleAssignment(item);
    }

    return {
      popup,
      v$,
      isUserEdit,
      isUserAssignable,
      homeOrg,
      roleAssignments: computed(() => roleAssignments.value ?? []),
      loading,
      user,
      error,
      isFullScreen,
      originalRoleAssignments: useResult(
        result,
        [],
        (data) => data.user.roleAssignments
      ),
      options: computed<IMenu<UserRoleAssignment>[]>(() =>
        isUserEdit
          ? [
              {
                ...useShowOption(root.$tc("organization.title")),
                disabled: (item) =>
                  !useCanNavigate("Organization", item?.organization),
                command: (item) =>
                  useNavigateToOrganization(
                    root.$router,
                    item?.organization?.id
                  ),
              },
              {
                ...useShowOption(root.$tc("role.title")),
                disabled: (item) => !useCanNavigate("Role", item?.role),
                command: (item) =>
                  useNavigateToRole(root.$router, item?.role?.id),
              },
              {
                ...useShowOption(root.$tc("application.title")),
                disabled: (item) =>
                  !useCanNavigate("Application", item?.role?.application),
                command: (item) =>
                  useNavigateToApplication(
                    root.$router,
                    item?.role?.application?.id
                  ),
              },
              {
                ...useRemoveOption(root.$tc("users.role_assignment")),
                command: (item) => removeRoleAssignment(item),
                disabled: (item) => !useCanRemove("UserRoleAssignment", item),
              },
            ]
          : [
              {
                ...useRemoveOption(root.$tc("users.role_assignment")),
                command: (item) => removeRoleAssignment(item),
                disabled: (item) => !useCanRemove("UserRoleAssignment", item),
              },
            ]
      ),
      fields: computed<TableField[]>(() =>
        isFullScreen.value
          ? [
              {
                key: "role.name",
                label: root.$tc("roles.title"),
                filter: true,
                sortable: true,
              },
              {
                key: "role.attribute",
                label: root.$tc("roles.fields.attribute"),
                filter: true,
                sortable: true,
              },
              {
                key: "expirationDate",
                label: "",
                width: "5%",
              },
              {
                key: "role.application.name",
                label: root.$tc("roles.fields.application"),
                filter: true,
                sortable: true,
              },
              {
                key: "organization.name",
                label: root.$tc("users.fields.on_behalf_of"),
                filter: true,
                sortable: true,
              },
              {
                key: "lastUse",
                label: root.$tc("users.fields.last_use"),
                sortable: true,
                width: "15%",
              },
            ]
          : [
              {
                key: "role.name",
                label: root.$tc("roles.title"),
                filter: true,
                sortable: true,
              },
              {
                key: "expirationDate",
                label: "",
                width: "10%",
              },
              {
                key: "organization.name",
                label: root.$tc("users.fields.on_behalf_of"),
                filter: true,
                sortable: true,
              },
            ]
      ),
      useFromNowDateFormatted,
      useHasPassed,
      addedRoleAssignment,
      removeRoleAssignment,
      roleUpdated: () => refetch(),
      loadingRemoveRoleAssignment,
    };
  },
});
