





































































import InputGroup from "@/components/InputGroup.vue";
import { useGetUserByEmailLazyQuery } from "@/graphql/types";
import {
  required,
  withI18nMessage,
  email,
  maxLength,
} from "@/utilities/validators";
import { helpers } from "@vuelidate/validators";
const { withAsync } = helpers;
import { defineComponent, watch, toRef } from "@vue/composition-api";
import injectStrict from "@/utilities/injectstrict";
import useVuelidate from "@vuelidate/core";
import { UserKey } from "../symbols";
import { useResult } from "@vue/apollo-composable";
import useProfile from "@/composables/useProfile";
import ParentView from "@/components/ParentView.vue";
import { usePromisable } from "@/utilities/useLazyApolloQuery";

export default defineComponent({
  components: { InputGroup, ParentView },
  setup() {
    const { profile, isHomeOrg } = useProfile();
    const user = injectStrict(UserKey);

    const { result, loading, fetch } = usePromisable(
      useGetUserByEmailLazyQuery(
        () => ({
          email: user.email ?? "",
        }),
        { fetchPolicy: "no-cache" }
      )
    );

    const v$ = useVuelidate(
      useValidations(existingEmailValidator),
      {
        email: toRef(user, "email"),
      },
      {
        $autoDirty: true,
        $lazy: true,
      }
    );

    async function existingEmailValidator() {
      if (user.email == "" || v$.value.email.email.$invalid) return false;
      const result = await fetch();
      return (
        (result && result.data && result.data.userByEmail != null) || false
      );
    }

    watch(
      () => useResult(result).value,
      (newValue) => {
        Object.assign(user, newValue);
      }
    );

    watch(
      () => user.email,
      () => {
        user.roleAssignments = [];
      }
    );

    return {
      v$,
      user,
      profile,
      isHomeOrg,
      loading,
    };
  },
});

function useValidations(validation: () => Promise<boolean>) {
  return {
    email: {
      required,
      email,
      maxLenght: maxLength(255),
      notExistingEmail: withAsync(withI18nMessage(validation)),
    },
  };
}
