















import { Role, useGetSelectableRolesQuery } from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import Select from "../Select.vue";

export default defineComponent({
  components: {
    Select,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    value: {
      type: Object as PropType<Role>,
    },
    disabledItem: {
      type: Function as PropType<(role: Role) => boolean>,
    },
  },
  setup(props, { emit }) {
    const { result, loading } = useGetSelectableRolesQuery({
      fetchPolicy: "cache-first",
    });
    const items = useResult(result, [], (data) => data.roles.nodes);
    return {
      items,
      disabledItems: computed(() => {
        return items.value.filter((element) => {
          if (props.disabledItem && props.disabledItem(element)) {
            return true;
          } else {
            return false;
          }
        });
      }),
      loading,
      item: computed<Role | undefined>({
        get() {
          return props.value;
        },
        set(newValue: Role | undefined): void {
          emit("input", newValue);
        },
      }),
      additionalSearchField: ["attribute"],
    };
  },
});
