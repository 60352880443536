import {
  GetUsersQuery,
  GetUsersQueryVariables,
  OnBehalfOfRoleInput,
  User,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import { InjectionKey, Ref } from "@vue/composition-api";

export const UserKey: InjectionKey<User> = Symbol();
export const EmailKey: InjectionKey<Ref<string>> = Symbol();
export const IsUserEditKey: InjectionKey<boolean> = Symbol();
export const AssignRolesKey: InjectionKey<Ref<OnBehalfOfRoleInput[]>> =
  Symbol();
export const ParentQueryKey: InjectionKey<
  QueryOptions<GetUsersQueryVariables, GetUsersQuery>
> = Symbol();
