














import { computed, defineComponent } from "@vue/composition-api";
import Message from "@/components/Message.vue";
import injectStrict from "@/utilities/injectstrict";
import IButton from "@/interfaces/IButton";
import { UserKey } from "../symbols";
import useNavigateToUser from "@/composables/navigation/useNavigateToUser";

export default defineComponent({
  components: {
    Message,
  },
  setup(_, { root }) {
    const user = injectStrict(UserKey);
    return {
      primaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("users.show"),
          variant: "success",
          onClick: async () => {
            await root.$router.back();
            setTimeout(() => {
              useNavigateToUser(root.$router, user.id);
            }, 500);
          },
        };
      }),
      secondaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.done"),
          variant: "info",
          onClick: () => {
            root.$router.back();
          },
        };
      }),
    };
  },
});
